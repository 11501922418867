import { application } from "../application"



// shared controllers
import AutoscrollController from "../shared/autoscroll_controller";
application.register("autoscroll", AutoscrollController);

import DatepickerController from "../shared/datepicker_controller";
application.register("datepicker", DatepickerController);

import FilepickerController from "../shared/filepicker_controller";
application.register("filepicker", FilepickerController);

import AutofocusController from "../shared/autofocus_controller";
application.register("autofocus", AutofocusController);

import AudioRecorderController from "../shared/audio_recorder_controller";
application.register("audio-recorder-controller", AudioRecorderController);

// app specific controllers
import TaskController from "./task_controller"
application.register("task-controller", TaskController)

import MessageController from "./message_controller";
application.register("message-highlight", MessageController);

import CodeHiglighController from "./code_highlight_controller";
application.register("code-highlight", CodeHiglighController);

import AutoSubmitController from "./auto_submit";
application.register("autosubmit", AutoSubmitController);

import ObjectiveChartController from "./objective_chart";
application.register("objective-chart", ObjectiveChartController);

import DataStreamChartController from "./data_stream_chart";
application.register("data-stream-chart", DataStreamChartController);

import MetricChartController from "./metric_chart";
application.register("metric-chart", MetricChartController);

import HeatmapController from "./heatmap_controller";
application.register("heatmap", HeatmapController);

import FractalController from "./fractal_controller";
application.register("fractal", FractalController);

import ChartController from "./chart_controller";
application.register("chart", ChartController);

import ModalController from "./modal_controller";
application.register("modal", ModalController);

import SlideoverController from "./slideover_controller";
application.register("slideover", SlideoverController);

import AppController from "./app_controller";
application.register("app", AppController);

import DataStreamBackfillController from "./data_stream_backfill_controller";
application.register("data-stream-backfill", DataStreamBackfillController);

import StravaMapController from "./strava_map";
application.register("strava-map", StravaMapController);